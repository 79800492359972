import { MFAMethodsEnum } from "@fry/system/account/mfa/mfa";

export class Organisation {
    id: string;
    name: string;
    shortName: string;

    enabledLabs: boolean;
    enabledMFAs: MFAMethodsEnum[];

    constructor(doc) {
        this.id = doc.id
        this.name = doc.name;
        this.shortName = doc.shortName;
        this.enabledLabs = doc.enabledLabs;
        this.enabledMFAs = doc.enabledMFAs;
    }
}
