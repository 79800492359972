import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { MFAService } from "@fry/system/account/mfa/mfa.service";

@Component({
  selector: 'eas-email-registration',
  templateUrl: './email-registration.component.html',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule]
})
export class EmailRegistrationComponent {
  @Input() credentialUsername: string;

  otpForm: FormGroup;
  verificationMessage: string = '';
  isOtpSent: boolean = false;
  mfaId: string = '';

  constructor(
    private fb: FormBuilder,
    private mfaService: MFAService
  ) {
    this.otpForm = this.fb.group({
      code: ['']
    });
  }

  async sendOtp() {
    const response = await this.mfaService.initEmailOtpRegistration(this.credentialUsername);
    if (response.state === "ok") {
      this.mfaId = response.data.mfa_id;
      this.isOtpSent = true;
      this.verificationMessage = 'OTP has been sent to your email.';
    } else {
      console.error('Error sending OTP:', response.error);
      this.verificationMessage = 'Failed to send OTP. Please try again later.';
    }
  }

  async verifyOtp() {
    const code = this.otpForm.value.code;
    const response = await this.mfaService.completeEmailOtpRegistration(
      this.credentialUsername,
      this.mfaId,
      code
    );
    if (response.state === "ok") {
      this.verificationMessage = 'OTP verified successfully!';
    } else {
      this.verificationMessage = 'OTP verification failed. Try again.';
    }
  }
}
