// Adjusted MfaService with methods used above
import { inject, Injectable } from '@angular/core';
import { Api, Response } from "@fry/core/api";
import { MFA } from "@fry/system/account/mfa/mfa";


export interface MFAIdResponse {
  mfa_id: string;
}

export interface TOTPInitRegistrationResponse {
  uri: string;
  mfa_id: string;
}


@Injectable({
  providedIn: 'root'
})
export class MFAService {
  private api = inject(Api);

  async getRegisteredMFAs(credentialUsername: string, accountId?: string): Promise<Response<MFA[]>> {
    const url = accountId
      ? `/v2/accounts/${accountId}/credentials/local/${credentialUsername}/mfas`
      : `/v2/account/credentials/local/${credentialUsername}/mfas`;
    return this.api.get<MFA[]>(url);
  }

  async initEmailOtpRegistration(credentialUsername: string): Promise<Response<MFAIdResponse>> {
    return this.api.post<MFAIdResponse>(`/v2/account/credentials/local/${credentialUsername}/mfas/email-otp/registration/init`);
  }

  async completeEmailOtpRegistration(credentialUsername: string, mfaId: string, code: string): Promise<Response<MFAIdResponse>> {
    return this.api.put<MFAIdResponse>(
      `/v2/account/credentials/local/${credentialUsername}/mfas/${mfaId}/email-otp/registration/complete`,
      { code: code }
    );
  }

  async initTotpRegistration(credentialUsername: string): Promise<Response<TOTPInitRegistrationResponse>> {
    return this.api.post<TOTPInitRegistrationResponse>(
      `/v2/account/credentials/local/${credentialUsername}/mfas/totp/registration/init`
    );
  }

  async completeTotpRegistration(credentialUsername: string, mfaId: string, code: string): Promise<Response<MFAIdResponse>> {
    return this.api.put<MFAIdResponse>(
      `/v2/account/credentials/local/${credentialUsername}/mfas/${mfaId}/totp/registration/complete`,
      { code: code }
    );
  }

  async initPasskeyRegistration(credentialUsername: string): Promise<Response<PublicKeyCredentialCreationOptions>> {
    return this.api.post<PublicKeyCredentialCreationOptions>(
      `/v2/account/credentials/local/${credentialUsername}/mfas/passkey/registration/init`
    );
  }

  async completePasskeyRegistration(credentialUsername: string, mfaId: string, passkey: Credential) {
    return this.api.post(
      `/v2/account/credentials/local/${credentialUsername}/mfas/${mfaId}/passkey/registration/complete`,
      { passkey: passkey }
    );
  }

  async deleteMFA(credentialUsername: string, mfaId: string, accountId?: string): Promise<Response<MFAIdResponse>> {
    const url = accountId
      ? `/v2/accounts/${accountId}/credentials/local/${credentialUsername}/mfas/${mfaId}`
      : `/v2/account/credentials/local/${credentialUsername}/mfas/${mfaId}`;
    return this.api.delete<MFAIdResponse>(url);
  }
}
