<div>
  <h3>Authenticator App (TOTP) Configuration</h3>
  <div *ngIf="qrCodeDataUrl">
    <p>Scan the QR code with your authenticator app:</p>
    <img [src]="qrCodeDataUrl" alt="TOTP QR Code" />
    <input id="copyField" type="text" [value]="uri" />
    <button type="button" (click)="copyToClipboard()">Copy</button>
  </div>
  <form [formGroup]="totpForm" (ngSubmit)="verifyTotp()">
    <label for="code">Enter code from authenticator app:</label>
    <input id="code" formControlName="code" type="text" />
    <button type="submit">Verify TOTP</button>
  </form>

  <p>{{ verificationMessage }}</p>
</div>
